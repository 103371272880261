.alert-secondary {
  background-color: #f5f7fa;
  border-color: #c8d1e0;
}

.btn-outline-secondary {
  margin: 0;
}

.card-header {
  background-color: #f5f7fa;
}

[dir] .table thead th {
  border-bottom: 2px solid #c8d1e0;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #377abc;
  border-color: #9fc9f1;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: #de1b1b;
  border-color: #fab6b6;
}

.btn-primary:active {
  background-color: #9fc9f1 !important;
  border-color: rgba(38, 143, 255, 50%) !important;
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, 50%);
}

.btn-danger:active {
  background-color: #fab6b6 !important;
  border-color: #fa8e8e !important;
}

.bg-danger {
  background-color: #b80d0d !important;
}

.bg-primary {
  background-color: #2866a2 !important;
}

.progress {
  background-color: #f5f7fa;
}

.form-control {
  background-color: #f5f7fa;
  border-color: #c8d1e0;
}

.form-control:disabled {
  background-color: #f5f7fa;
  border-color: #c8d1e0;
}

.form-control:hover {
  background: #fff;
}

.form-control.is-valid:focus,
.was-validated .form-control:focus {
  border-color: #86b7fe !important;
  box-shadow: 0 0 0 .25rem #0d6efd40 !important;
}

.was-validated .form-control:focus {
  border-color: #ced4da;
  box-shadow: none;
}

body .dropdown-visible > div.multiselect-dropdown > div.dropdown-list {
  display: block !important;
  margin-top: 0;
}

body .dropdown-visible > div.multiselect-dropdown > div > span.dropdown-btn {
  display: none !important;
}

.ngb-dp-week > .ngb-dp-day > .bg-primary {
  background-color: #205282 !important;
}

.multiselect-dropdown > .dropdown-list {
  min-width: 200px;
  border-radius: 5px !important;
}

.ngb-datepicker-container-inner {
  box-shadow: 0 0 10px #b3b3b3;
  border-radius: 6px !important;
  background: white;
  padding: 5px 5px 53px;
}

.tenant-badge a.badge.bg-primary:hover,
.tenant-badge a.badge.bg-primary:active,
.tenant-badge a.badge.bg-primary:focus {
  color: #fff !important;
  background: #66b0ff !important;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757e;
  border-color: #6c757e;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6269;
  border-color: #545b63;
}

.active>.page-link,
.page-link.active,
.form-check-input:checked {
  background-color: #2866a2;
  border-color: #205282;
}

.input-group > .btn-outline-secondary {
  border: 1px solid #c8d1e0;
}

.text-primary {
  color: #205282 !important;
}
